import React from 'react';

export default function(__params = {}) {
  const { links } = __params;
  return (
    <nav>
      <ul>
        {(links || []).map((link, i) => (
          <li key={i}>
            <i className={`${link.iconType} fa-${link.iconCode} fa-fw`}></i>&ensp;<a href={link.href}>{link.title}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
