import React from 'react';

export default function(__params = {}) {
  const { LinksList, links, meta } = __params;
  return (
    <>
      <div className="outer" id="header_wrap">
        <header className="inner">
          <a id="forkme_banner" href="https://github.com/OscardR">
            View on GitHub
          </a>
          <h1 id="project_title">
            {meta.title} <small>v{meta.version}</small>
          </h1>
          <h2 id="project_tagline">{meta.tagLine}</h2>
        </header>
      </div>
      <div className="outer" id="main_content_wrap">
        <section className="inner" id="main_content">
          <LinksList links={links}></LinksList>
        </section>
      </div>
      <div className="outer" id="footer_wrap">
        <footer className="inner">
          <p>
            Built with{' '}
            <a href="https://www.gatsbyjs.com/">
              <i className="fab fa-react"></i> GatsbyJS{' '}
            </a>
            and deployed and hosted via{' '}
            <a href="https://www.netlify.com/">
              <i className="fab fa-git-alt"></i> Netlify
            </a>
          </p>
        </footer>
      </div>
    </>
  );
}
